import Helper from '@/services/helper';

export default {
    async getAuthorProfile(id) {
        const param = {
            id: id
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/author/profile', param);
        
        return result;
    },
    async getContentList(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/author/content-list', param);
        
        return result;
    }
}