<template>
	<div class="container">
		<div class="author-content" v-if="authorProfile">
			<section class="author">
				<div class="portrait">
                    <b-icon icon="person" class="icon"></b-icon>
                </div>
                <div class="profile">
                    <label><b-icon icon="pencil" class="icon"></b-icon> ผู้เขียน</label>
                    <h1>
                        {{ authorProfile.name }}
                    </h1>
					<p>
						{{ authorProfile.profile }}
					</p>
                </div>
			</section>
			<section class="contents">
				<h2><b-icon icon="book" class="icon"></b-icon> บทความทั้งหมดโดยผู้เขียน</h2>

				<div class="blogs">
					<div class="loading" v-if="!isResultReady">
						<loader-content :isDisplay="true"></loader-content>
					</div>

					<router-link :to="'/' + item.landing + '/' + item.urlParam" :title="item.title" class="item" v-for="(item, index) in resultList" :key="index">
						<img :src="item.picture" :alt="item.pictureAlt" :title="item.pictureAlt" @error="helper.setNoImg($event, 'blog')" />

						<div class="description">
							<h3>{{ item.title }}</h3>
							<p>{{ item.abstract }}</p>

							<div class="bottom">
								<div class="date">
									<font-awesome-icon :icon="['fas', 'calendar-alt']" class="icon" />
									{{ item.date }}
								</div>
								<div>
									{{ item.view }} views
								</div>
							</div>
						</div>
					</router-link>
				</div>
				<paging
					@page="changePage($event)"
					:activePage="activePage"
					:totalPage="totalPage"
					class="mt-4">
				</paging>
			</section>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import AuthorService from '@/services/authorService';
import RouterPath from '@/router/path';

export default {
	data() {
		return {
			isContentReady: false,
			isResultReady: false,
			resultList: [],
			activePage: 1,
			totalPage: 0,
			routerPath: RouterPath,
            authorProfile: null,
            pageTitle: '',
            metaDescription: ''
		};
	},
	mounted() {
        this.getContent();
		this.getAuthorBlog();
    },
    methods: {
        async getContent() {
            this.isContentReady = false;

            const result = await AuthorService.getAuthorProfile(this.$route.params.id);

			this.authorProfile = result.data.authorProfile;
			this.pageTitle = 'บทความ/ข่าวสาร/โปรโมชั่น โดย ' + this.authorProfile.name + ' | YELLOWTiRE';
			this.metaDescription = 'รายการข่าวสารวงการรถยนต์และยานยนต์ ความรู้เรื่องยางรถยนต์ โปรโมชั่นยางรถยนต์ โดย ' + this.authorProfile.name + ' YELLOWTiRE';
            this.isContentReady = true;
        },
		async getAuthorBlog() {
            this.isResultReady = false;

			const param = this.setupParamContent();
            const result = await AuthorService.getContentList(param);

			this.resultList = result.data.resultList;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
        },
		setupParamContent() {
			const param = {
				page: this.activePage,
				authorId: this.$route.params.id
			};

			return param;
		},
		changePage(page) {
			this.activePage = page;
			this.getAuthorBlog();
		}
    },
	metaInfo() {
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: this.pageTitle,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: this.metaDescription },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: this.pageTitle },
                { vmid: 'og-desc', property: 'og:description', content: this.metaDescription },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: this.metaDescription },
                { vmid: 'twitter-title', property: 'twitter:title', content: this.pageTitle },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.author-content {
	margin: 30px 0;
	padding: 20px;
	background: #fff;
	display: flex;

	@media only screen and (max-width: $screenMedium) {
		margin-top: 0;
		display: block;
	}
	
	.author {
        border-right: 1px solid #666;
        margin-right: 25px;
        padding-right: 25px;
		width: 220px;
		text-align: center;

		@media only screen and (max-width: $screenMedium) {
			width: 100%;
			border-right: 0;
			margin-bottom: 25px;
			padding-bottom: 0;
			display: flex;
			text-align: left;
		}

        .portrait {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
			margin: auto;
            width: 70px;
            height: 70px;
            border: 1px solid #333;
            @include borderRadius(50%);

            .icon {
                font-size: 36px;
            }

			@media only screen and (max-width: $screenMedium) {
				margin: unset;
			}
        }

        .profile {
			margin-top: 10px;

			@media only screen and (max-width: $screenMedium) {
				margin-top: 0;
				flex: 1;
				margin-left: 25px;
			}

            label {
                display: block;
                font-size: 12px;
                margin-bottom: 3px;
                color: #555;

                .icon {
                    margin-right: 5px;
                }
            }

            h1 {
                display: block;
                color: #333;
                font-size: 16px;
				font-family: 'Open Sans', 'Kanit';
				font-weight: bold;
				margin: 0;
            }

			p {
				font-size: 13px;
				color: #777;
				margin: 10px 0 0;

				@media only screen and (max-width: $screenMedium) {
					margin-top: 4px;
				}
			}
        }
    }

	.contents {
		flex: 1;

		h2 {
			font-size: 18px;
			font-family: 'Open Sans', 'Kanit';
			font-weight: bold;
			border-bottom: 1px dashed #aaa;
			margin-bottom: 10px;
			padding-bottom: 15px;

			.icon {
				margin-right: 5px;
			}
		}

		.blogs {
			margin-top: 25px;

			a {
				position: relative;
				color: #000;
				display: flex;
				margin-top: 20px;

				@media only screen and (max-width: $screenExtraSmall) {
					display: block;
					margin-top: 30px;
				}

				img {
					width: 300px;
					display: block;

					@media only screen and (max-width: $screenMedium) {
						width: 250px;
					}

					@media only screen and (max-width: $screenSmall) {
						width: 170px;
					}

					@media only screen and (max-width: $screenExtraSmall) {
						width: 100%;
					}
				}

				.description {
					padding-left: 20px;

					@media only screen and (max-width: $screenSmall) {
						padding-left: 10px;
					}

					@media only screen and (max-width: $screenExtraSmall) {
						padding-left: 0;
						padding-top: 12px;
					}

					h3 {
						margin-bottom: 10px;
						font-size: 14px;
						font-weight: bold;
						line-height: 18px;
						color: #000;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;

						@media only screen and (max-width: $screenSmall) {
							font-size: 13px;
							line-height: 16px;
						}
					}

					p {
						font-size: 12px;
						line-height: 17px;
						color: #555;
						margin-bottom: 0;
						display: -webkit-box;
						-webkit-line-clamp: 4;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.bottom {
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: 12px;
						padding-top: 10px;

						@media only screen and (max-width: $screenLarge) {
							font-size: 11px;
						}

						.date {
							.icon {
								color: #ffcc00;
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
	}
}
</style>